<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="客户">
        <base-select info="Client" v-model="filter.clientId"></base-select>
      </el-form-item>
      <el-form-item label="合同">
        <ContractSelect v-model="filter.contractId"></ContractSelect>
      </el-form-item>
      <el-form-item label="显示所有">
        <el-switch v-model="filter.showAll"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh"
          >刷新</el-button
        >
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size: 12px"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <pl-table
      :data="data"
      border
      stripe
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclickTable"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        label="单据类型"
        prop="billType"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="单据日期"
        prop="billDate"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{
          scope.row.billDate | datetime
        }}</template>
      </el-table-column>
      <el-table-column
        label="单据编号"
        prop="billCode"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="应收金额"
        prop="shouldAmount"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{
          scope.row.shouldAmount | thousands
        }}</template>
      </el-table-column>
      <el-table-column label="结算金额" prop="payAmount" show-overflow-tooltip>
        <template slot-scope="scope">{{
          scope.row.payAmount | thousands
        }}</template>
      </el-table-column>
      <el-table-column label="结存金额" prop="endAmount" show-overflow-tooltip>
        <template slot-scope="scope">{{
          scope.row.endAmount | thousands
        }}</template>
      </el-table-column>
      <el-table-column
        label="备注"
        prop="remark"
        show-overflow-tooltip
      ></el-table-column>
    </pl-table>
  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect.vue";
import ContractSelect from "@/components/ContractSelect.vue";
import toExcel from "@/common/toExcel";
import tableHeight from "@/common/tableHeightMixins";
export default {
  mixins: [tableHeight],
  components: {
    BaseSelect,
    ContractSelect,
  },
  data() {
    return {
      filter: {
        begin: this.$moment().subtract(1, "months").format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD"),
        showAll: false,
      },
      data: [],
    };
  },
  activated() {
    let item = this.$store.state.reportfilter.cache.find(
      (r) => r.name == this.$route.name
    );
    if (item) {
      this.filter = { ...item.filter };
    }
    this.getData();
  },
  methods: {
    tableRowClassName({ row }) {
      if (!row.billId) {
        return "bold-row";
      } else {
        return "";
      }
    },
    getData() {
      this.$store.commit("deleteFilterCache", this.$route.name);
      let params = { ...this.filter };
      if (!params.begin) {
        this.$message.error("请选择开始日期！");
        return;
      }
      if (!params.end) {
        this.$message.error("请选择结束日期！");
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$get("ReceiptReport/Detail", params)
        .then((r) => {
          let endAmount = 0;
          r.forEach((item) => {
            if (!item.billId) {
              endAmount = item.endAmount;
            } else {
              endAmount = this.amountFormat(
                endAmount + item.shouldAmount - item.payAmount
              );
              item.endAmount = endAmount;
            }
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    excel() {
      let list = [];
      this.data.forEach((item) => {
        list.push({
          billType: item.billType,
          billDate: item.billDate
            ? this.$moment(item.billDate).format("YYYY-MM-DD")
            : "",
          billCode: item.billCode || "",
          shouldAmount: this.amountFormat(item.shouldAmount),
          payAmount: this.amountFormat(item.payAmount),
          endAmount: this.amountFormat(item.endAmount),
          remark: item.remark,
        });
      });
      let header = [
        "单据类型",
        "单据日期",
        "单据编号",
        "应收金额",
        "结算金额",
        "结存金额",
        "备注",
      ];
      let column = [
        "billType",
        "billDate",
        "billCode",
        "shouldAmount",
        "payAmount",
        "endAmount",
        "remark",
      ];
      toExcel(header, column, list, "应收账款明细");
    },
    dblclickTable(row) {
      let billId = row.billId;
      if (!billId) {
        return;
      }
      switch (row.billType) {
        case "往来合同":
          if (this.hasPermission("Contract")) {
            this.$router.push({
              name: "ContractDetail",
              params: { id: billId },
            });
          }
          break;
        case "合同收款":
        case "初始化收款":
          if (this.hasPermission("Receipt")) {
            this.$router.push({
              name: "ReceiptItem",
              query: { id: billId },
            });
          }
          break;
      }
    },
  },
};
</script>

<style>
</style>