<template>
  <el-select
    :value="value"
    @change="
      (v) => {
        this.$emit('input', v);
      }
    "
    placeholder="请选择"
    clearable
    filterable
    :filter-method="filter"
    @clear="filter()"
  >
    <el-option
      v-for="item in list"
      :key="item.id"
      :value="item.id"
      :label="item.name"
    >
      <span class="code">{{ item.code }}</span>
      <span style="float: right">{{ item.name }}</span>
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "ContractSelect",
  props: ["value"],
  data() {
    return {
      all: [],
      list: [],
    };
  },
  mounted() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.$get("Contract/AllContracts").then((r) => {
        this.all = r;
        this.filter();
      });
    },
    filter(val) {
      if (val) {
        this.list = this.all.filter((item) => {
          if (
            item.name.indexOf(val) >= 0 ||
            item.pinYin.indexOf(val.toLowerCase()) >= 0 ||
            item.code.indexOf(val) >= 0
          ) {
            return true;
          }
        });
      } else {
        this.list = this.all;
      }
    },
  },
};
</script>

<style scoped>
.code {
  float: left;
  color: #999;
  font-size: 12px;
  margin-right: 20px;
}
</style>